import React, {Component} from 'react';
import styled from 'styled-components'
import {Link} from "gatsby"
import Social from "components/shared/Social"

const date = new Date();

const FooterWrapper = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.yellow};
  text-align: center;
  padding: 2rem 1rem;
  
  a {
    color: ${props => props.theme.colors.yellow};
    transition: all 0.2s ease-in;
    text-decoration: none;
    
    &:hover {
      color: ${props => props.theme.colors.red};
      text-decoration: none;
    }
  }
`

class Footer extends Component {
    render() {
        return (
            <FooterWrapper>
                        <div className="Footer__content">
                            <p>© {date.getFullYear()} Scout London Ltd. All Rights Reserved</p>
                            <p>Gielgud Theatre, London | A Delfont Mackintosh Theatre</p>
                            <Social/>
                            <div className="d-flex align-items-center justify-content-center">
                                <Link className="mx-2" title="Privacy policy" to="/privacy-policy/">Privacy policy</Link>
                                <span>|</span>
                                <Link className="mx-2" title="Cookie policy" to="/cookie-policy/">Cookie policy</Link>
                            </div>
                        </div>
            </FooterWrapper>
        );
    }
}

export default Footer;